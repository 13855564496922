import FavoriteIcon from "@mui/icons-material/Favorite";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { CoachMatch } from "@trainwell/api-sdk";
import { trainerEducation } from "@trainwell/features/legacy";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { useEffect, useState, type JSX } from "react";
import { experienceData } from "src/components/pages/Tags/data/experienceData";
import specialtiesData from "src/components/pages/Tags/data/specialtiesData";
import { useAppSelector } from "src/hooks/stateHooks";
import {
  convertToLocalTime,
  formatUTCTime,
  getUTCDayOfWeekLabel,
} from "src/lib/misc";
import { selectActiveTests } from "src/slices/analyticsSlice";
import AvailabilityDay from "./AvailabilityDay";
import CoachInfo_June from "./CoachInfo_June";

type Props = {
  trainer: CoachMatch;
  selectTrainerHandler: (trainerID: CoachMatch) => void;
  isActive?: boolean;
};

export default function CoachCard_June({
  trainer,
  selectTrainerHandler,
  isActive = true,
}: Props) {
  const [chipData, setChipData] = useState<
    { label: string; color: string; icon: JSX.Element }[]
  >([]);

  function getAvailabilityDays(): {
    dayLabel: string;
    dayNumber: number;
    times: string[];
  }[] {
    const slots = trainer.availability_slots;

    if (slots) {
      const availableOnboardingSlots = slots.filter(
        (slot) => !slot.busy && slot.call_type === "onboarding",
      );

      //Shift all slot dates to be in local time
      const shiftedSlots = availableOnboardingSlots.map((slot) => {
        return {
          date_start: convertToLocalTime(new Date(slot.date_start)),
          date_end: convertToLocalTime(new Date(slot.date_end)),
        };
      });

      //Compute start of tomorrow in local time
      const startOfTomorrow = startOfDay(addDays(new Date(), 1));

      const availabilityDays: {
        dayLabel: string;
        dayNumber: number;
        times: string[];
      }[] = [];

      //Loop through upcoming days and find slots
      for (let i = 0; i < 7; i += 1) {
        const dayStart = addDays(startOfTomorrow, i);
        const dayEnd = endOfDay(dayStart);

        const daySlots = shiftedSlots.filter(
          (slot) =>
            slot.date_start.getTime() >= dayStart.getTime() &&
            slot.date_start.getTime() <= dayEnd.getTime(),
        );

        availabilityDays.push({
          dayLabel: getUTCDayOfWeekLabel(dayStart), //Use UTC because we already shifted to local time (so "UTC" is local)
          dayNumber: dayStart.getUTCDate(), //Use UTC because we already shifted to local time (so "UTC" is local)
          times: daySlots.map((slot) => formatUTCTime(slot.date_start)),
        });
      }

      return availabilityDays;
    }

    return [];
  }

  const days = getAvailabilityDays();
  const tagExperience = useAppSelector((state) => state.survey.tagExperience);
  const tagSpecializations = useAppSelector(
    (state) => state.survey.tagSpecializations,
  );
  const activeTestIds = useAppSelector(selectActiveTests);

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const newChipData: typeof chipData = [];

    //Only show one education (their highest level)
    if (trainer.education?.includes(trainerEducation.masters)) {
      newChipData.push({
        label: trainerEducation[trainerEducation.masters],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    } else if (trainer.education?.includes(trainerEducation.bachelors)) {
      newChipData.push({
        label: trainerEducation[trainerEducation.bachelors],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    } else if (trainer.education?.includes(trainerEducation.associates)) {
      newChipData.push({
        label: trainerEducation[trainerEducation.associates],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    }

    trainer.education?.forEach((education) => {
      newChipData.push({
        label: trainerEducation[education],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    });

    if (trainer.years_experience) {
      newChipData.push({
        label: trainer.years_experience + " years experience",
        color: "#FFDD99",
        icon: <WorkRoundedIcon />,
      });
    }

    const filteredSpecialties = (
      trainer.onboarding_tags?.specialties ?? []
    ).filter((tag) => tagSpecializations.includes(tag));

    filteredSpecialties.forEach((tag) => {
      newChipData.push({
        label: specialtiesData.find((data) => data.key === tag)?.label ?? "",
        color: "#FFB589",
        icon: specialtiesData.find((data) => data.key === tag)?.icon ?? <></>,
      });
    });

    const filteredExperience = (
      trainer.onboarding_tags?.experience ?? []
    ).filter((tag) => tagExperience.includes(tag));

    filteredExperience.forEach((tag) => {
      newChipData.push({
        label: experienceData.find((data) => data.key === tag)?.label ?? "",
        color: "#A7B2E8",
        icon: experienceData.find((data) => data.key === tag)?.icon ?? <></>,
      });
    });

    trainer.loves.forEach((love) => {
      newChipData.push({
        label: love,
        color: "#C8E3F9",
        icon: <FavoriteIcon />,
      });
    });

    setChipData(newChipData);
  }, []);

  return (
    <Box
      maxWidth={isPhone ? "90vw" : "60vw"}
      sx={{
        padding: "16px",
        border: "1px solid #E1E0E3",
        borderRadius: "16px",
      }}
    >
      <CoachInfo_June coach={trainer} chipData={chipData} />
      {activeTestIds.includes("jun_multiple_trainers_availability") && (
        <Box sx={{ marginTop: "16px", maxWidth: "100%", marginRight: "-16px" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {trainer.first_name}&apos;s onboarding call availability:
          </Typography>
          <Box
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            {days.map((day) => (
              <AvailabilityDay key={day.dayNumber} {...day} />
            ))}
          </Box>
          <Typography sx={{ color: "#888" }} variant="caption">
            All times in{" "}
            {new Date()
              .toLocaleDateString(undefined, {
                day: "2-digit",
                timeZoneName: "short",
              })
              .substring(4)}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "1.5em",
        }}
      >
        <Button
          sx={{
            width: "100%",
          }}
          onClick={() => {
            selectTrainerHandler(trainer);
          }}
          disabled={!isActive}
        >
          {isActive
            ? `Partner with ` + trainer.first_name
            : trainer.first_name + " is unavailable"}
        </Button>
      </Box>
    </Box>
  );
}
