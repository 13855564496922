import type { TagIdentity } from "@trainwell/features/legacy";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { selectTagDemographics } from "src/slices/surveySlice";
import Tags from "./components/Tags";
import TagsPageLayout from "./components/TagsPageLayout";
import identityData from "./data/identityData";

export default function TagIdentityPage() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const tags = useAppSelector((state) => state.survey.tagDemographics);
  const activeTestsIds = useAppSelector(selectActiveTests);

  function selectTag(tag: string) {
    dispatch(selectTagDemographics(tag as TagIdentity));
  }

  const onClick = () => {
    track("Identity Select", { tags: tags.length > 0 ? tags : ["empty"] });
    nextPage();
  };

  return (
    <TagsPageLayout
      title={`Trainer Identity`}
      subtitle={`Select any that are important for your trainer to be.`}
      onContinue={onClick}
    >
      <Tags tags={identityData} onTagClick={selectTag} selected={tags} />
    </TagsPageLayout>
  );
}
