import {
  Autocomplete,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import type { ProjectedInfluencer } from "@trainwell/features/influencers";
import { getInfluencersWithRecentCampaigns } from "@trainwell/features/influencers";
import { useEffect, useState } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import {
  submitUserSource,
  submitUserSourceInfluencer,
} from "src/slices/analyticsSlice";

export default function Source() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const brand = useAppSelector((state) => state.analytics.brand);
  const disableYoutubeSourceQuestion = useAppSelector(
    (state) => state.analytics.disableYoutubeSourceQuestion,
  );
  const [influencers, setInfluencers] = useState<ProjectedInfluencer[]>([]);
  const [selectedInfluencerId, setSelectedInfluencerId] = useState<
    string | null
  >(null);
  const [showInfluencerSelection, setShowInfluencerSelection] = useState(false);

  useEffect(() => {
    getInfluencersWithRecentCampaigns().then((data) => {
      setInfluencers(
        data.influencers.sort((a, b) =>
          a.display_name.localeCompare(b.display_name),
        ),
      );
    });
  }, []);

  let possibleSources = [
    "Reddit",
    "Friend or family",
    "Google",
    "YouTube",
    "TikTok",
    "Facebook",
    "Podcast",
    "App Store",
    "Other",
  ];

  if (brand === "af") {
    possibleSources = [
      "Email",
      "Gym physical marketing",
      "Staff recommendation",
      "Other",
    ];
  }

  function selectSource(source: string) {
    dispatch(submitUserSource(source));
    track("Source Select", { source: source });

    if (source === "YouTube" && !disableYoutubeSourceQuestion) {
      setShowInfluencerSelection(true);
    } else {
      survey.nextPage();
    }
  }

  function selectInfluencer(influencerId: string) {
    if (influencerId.toLowerCase() === "other") {
      survey.nextPage();
      return;
    }

    dispatch(submitUserSourceInfluencer(influencerId));
    survey.nextPage();
  }

  const influencerOptions = [
    "Other",
    ...(influencers ?? [])
      .sort((a, b) =>
        a.display_name.trim().localeCompare(b.display_name.trim()),
      )
      .map((i) => i.id),
  ];

  if (showInfluencerSelection) {
    return (
      <Container maxWidth="xs">
        <Typography variant="subtitle1" sx={{ mb: 4 }}>
          Which youtuber?
        </Typography>
        <Autocomplete
          sx={{ mb: 4 }}
          options={influencerOptions}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            influencers?.find((i) => i.id === option)?.display_name ?? option
          }
          blurOnSelect
          getOptionKey={(option) =>
            influencers?.find((i) => i.id === option)?.id ?? option
          }
          value={selectedInfluencerId ?? ""}
          onChange={(event, newInfluencerId) => {
            if (!newInfluencerId) {
              return;
            }

            setSelectedInfluencerId(newInfluencerId);
            selectInfluencer(newInfluencerId);
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Influencer" />}
        />
        {/* <FormControl fullWidth>
          <InputLabel id="influencer-label">Youtuber</InputLabel>
          <Select
            labelId="influencer-label"
            id="influencer-select"
            value={selectedInfluencerId}
            label="Youtuber"
            onChange={(event) => {
              setSelectedInfluencerId(event.target.value);
              selectInfluencer(event.target.value as string);
            }}
            MenuProps={{
              sx: { maxHeight: 400 },
            }}
            sx={{ mb: 2 }}
          >
            <MenuItem value={"other"}>Other</MenuItem>
            {influencers.map((influencer) => (
              <MenuItem key={influencer.id} value={influencer.id}>
                {influencer.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        Last question, where did you hear about us?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {possibleSources.map((source) => {
          return (
            <Button
              key={source}
              sx={{
                mb: 2,
              }}
              onClick={() => {
                selectSource(source);
              }}
            >
              {source}
            </Button>
          );
        })}
      </Box>
    </Container>
  );
}
