import FavoriteIcon from "@mui/icons-material/Favorite";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { CoachMatch } from "@trainwell/api-sdk";
import { trainerEducation } from "@trainwell/features/legacy";
import { useEffect, useState, type JSX } from "react";
import { experienceData } from "src/components/pages/Tags/data/experienceData";
import specialtiesData from "src/components/pages/Tags/data/specialtiesData";
import { useAppSelector } from "src/hooks/stateHooks";
import CoachInfo from "./CoachInfo";
import CoachProfileDialog from "./CoachProfileDialog";
import MeetYourCoach from "./MeetYourCoach";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

type Props = {
  trainer: CoachMatch;
  justificationBullets: { title: string; body: string }[] | undefined;
  selectTrainerHandler: (trainerID: CoachMatch) => void;
  isActive?: boolean;
};

export default function CoachCard({
  trainer,
  justificationBullets,
  selectTrainerHandler,
  isActive = true,
}: Props) {
  const [profileOpen, setProfileOpen] = useState(false);
  const [chipData, setChipData] = useState<
    { label: string; color: string; icon: JSX.Element }[]
  >([]);

  const tagExperience = useAppSelector((state) => state.survey.tagExperience);
  const tagSpecializations = useAppSelector(
    (state) => state.survey.tagSpecializations,
  );

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const newChipData: typeof chipData = [];

    //Only show one education (their highest level)
    if (trainer.education?.includes(trainerEducation.masters)) {
      newChipData.push({
        label: trainerEducation[trainerEducation.masters],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    } else if (trainer.education?.includes(trainerEducation.bachelors)) {
      newChipData.push({
        label: trainerEducation[trainerEducation.bachelors],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    } else if (trainer.education?.includes(trainerEducation.associates)) {
      newChipData.push({
        label: trainerEducation[trainerEducation.associates],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    }

    trainer.education?.forEach((education) => {
      newChipData.push({
        label: trainerEducation[education],
        color: "#B3E5B6",
        icon: <SchoolRoundedIcon />,
      });
    });

    if (trainer.years_experience) {
      newChipData.push({
        label: trainer.years_experience + " years experience",
        color: "#FFDD99",
        icon: <WorkRoundedIcon />,
      });
    }

    const filteredSpecialties = (
      trainer.onboarding_tags?.specialties ?? []
    ).filter((tag) => tagSpecializations.includes(tag));

    filteredSpecialties.forEach((tag) => {
      newChipData.push({
        label: specialtiesData.find((data) => data.key === tag)?.label ?? "",
        color: "#FFB589",
        icon: specialtiesData.find((data) => data.key === tag)?.icon ?? <></>,
      });
    });

    const filteredExperience = (
      trainer.onboarding_tags?.experience ?? []
    ).filter((tag) => tagExperience.includes(tag));

    filteredExperience.forEach((tag) => {
      newChipData.push({
        label: experienceData.find((data) => data.key === tag)?.label ?? "",
        color: "#A7B2E8",
        icon: experienceData.find((data) => data.key === tag)?.icon ?? <></>,
      });
    });

    trainer.loves.forEach((love) => {
      newChipData.push({
        label: love,
        color: "#C8E3F9",
        icon: <FavoriteIcon />,
      });
    });

    setChipData(newChipData);
  }, []);

  if (isPhone) {
    return (
      <Box>
        <MeetYourCoach coach={trainer} />
        <CoachInfo coach={trainer} chipData={chipData} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "1.5em",
          }}
        >
          <Button
            sx={{
              width: "100%",
            }}
            onClick={() => {
              selectTrainerHandler(trainer);
            }}
            disabled={!isActive}
          >
            {isActive
              ? `Partner with ` + trainer.first_name
              : trainer.first_name + " is unavailable"}
          </Button>
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              flexDirection: "column",
              gap: "16px",
            },
            px: 1,
            my: 4,
          }}
        >
          {justificationBullets?.map((bullet) => (
            <Box key={bullet.title}>
              <Typography>
                <span style={{ fontWeight: "bold" }}>{bullet.title}: </span>
                {bullet.body}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ height: "5em" }} />
      </Box>
    );
  } else {
    return (
      <>
        <Grid
          style={{
            display: "grid",
            marginTop: 30,
            gap: "16px",
            gridTemplateColumns: isPhone ? "100%" : "40% 60%",
          }}
        >
          <CoachInfo coach={trainer} chipData={chipData} />
          <Box>
            <MeetYourCoach coach={trainer} />
            <Box
              sx={{
                display: { xs: "flex", sm: "flex" },
                flexDirection: "column",
                gap: "12px",
                my: 3,
              }}
            >
              {justificationBullets?.map((bullet) => (
                <Box key={bullet.title}>
                  <Typography>
                    <span style={{ fontWeight: "bold" }}>
                      • {bullet.title}:{" "}
                    </span>
                    {bullet.body}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "1.5em",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                }}
                onClick={() => {
                  selectTrainerHandler(trainer);
                }}
                disabled={!isActive}
              >
                {isActive
                  ? `Partner with ` + trainer.first_name
                  : trainer.first_name + " is unavailable"}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        ></Box>

        <CoachProfileDialog
          isOpen={profileOpen}
          trainer={trainer}
          handleClose={() => {
            setProfileOpen(false);
          }}
          chipData={chipData}
          selectTrainerHandler={selectTrainerHandler}
        />
      </>
    );
  }
}
