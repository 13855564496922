import type { Trainer } from "@trainwell/features/legacy";
import { sha1 } from "js-sha1";

export function kilosToPounds(kilos: number): number {
  return roundHalf(kilos * 2.20462);
}

export function poundsToKilos(pounds: number): number {
  return roundHalf(pounds * 0.453592);
}

export function centimetersToInches(centimeters: number): number {
  return roundHalf(centimeters / 2.54);
}

export function inchesToCentimeters(inches: number): number {
  return roundHalf(inches * 2.54);
}

export function getWeightUnit(system: "imperial" | "metric"): string {
  if (system === "metric") {
    return "kg";
  } else {
    return "lbs";
  }
}

function roundHalf(num: number) {
  return Math.round(num * 2) / 2;
}

export function getCoachName(coach: Pick<Trainer, "first_name" | "last_name">) {
  return `${coach.first_name} ${Array.from(coach.last_name)[0]}.`;
}

export function sha1Hash(s: string): string {
  const hash = sha1.create();
  hash.update(s);
  return hash.hex();
}

/**
 * Converts a UTC Date object to a local Date object.
 * @param {Date} utcDate - The UTC Date object.
 * @returns {Date} - The local Date object.
 */
export function convertToLocalTime(utcDate: Date): Date {
  // Ensure the input is a valid Date object
  if (!(utcDate instanceof Date) || isNaN(utcDate.getTime())) {
    throw new Error("Invalid Date object");
  }

  // Get the timezone offset in minutes and convert it to milliseconds
  const timezoneOffset = utcDate.getTimezoneOffset() * 60000;

  // Create a new Date object in local time
  const localTime = new Date(utcDate.getTime() - timezoneOffset);

  return localTime;
}

/**
 * Formats a Date object into a string like "10:00 am" or "3:45 pm".
 * @param {Date} date - The Date object.
 * @returns {string} - The formatted time string.
 */
export function formatUTCTime(date: Date): string {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid Date object");
  }

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  return `${hours}:${minutesStr} ${ampm}`;
}

export function getUTCDayOfWeekLabel(date: Date): string {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid Date object");
  }

  // Array of day labels
  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Get the day of the week (0-6)
  const dayIndex = date.getUTCDay();

  // Return the corresponding day label
  return dayLabels[dayIndex];
}
