import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import type { Brand } from "@trainwell/features/legacy";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "./store";

type BrandState = {
  brand: Brand | null;
};
const initialState: BrandState = { brand: null };

export const getBrand = createAsyncThunk(
  "brand/getBrand",
  async (brandId: string) => {
    const brand = await api.brands.getBrandById(brandId);
    return brand;
  },
);

const brandSlice = createSlice({
  name: "brand",
  initialState: initialState,
  reducers: {
    setBrandObject: (state, action: PayloadAction<Brand | null>) => {
      state.brand = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrand.fulfilled, (state, action) => {
      state.brand = action.payload.brand;
    });
    builder.addCase(getBrand.pending, (state) => {
      state.brand = null;
    });
    builder.addCase(getBrand.rejected, (state) => {
      state.brand = null;
    });
  },
});

export const { setBrandObject } = brandSlice.actions;

export const selectBrand = (state: RootState) => {
  return state.brand.brand;
};

export default brandSlice.reducer;
