import type { TagObWindowsPreferred } from "@trainwell/features/legacy";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import {
  selectObWindowsPreferredTags,
  selectTagObWindowsPreferred,
} from "src/slices/surveySlice";
import Tags from "./components/Tags";
import TagsPageLayout from "./components/TagsPageLayout";
import { obWindowsCopy } from "./data/challengeData";

export default function TagObWindowsPreferred() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const tags = useAppSelector(selectObWindowsPreferredTags);
  const activeTestsIds = useAppSelector(selectActiveTests);

  function selectTag(tag: string) {
    dispatch(selectTagObWindowsPreferred(tag as TagObWindowsPreferred));
  }

  const onClick = () => {
    nextPage();
  };

  return (
    <>
      <TagsPageLayout
        title="Preferred Onboarding Call Time"
        subtitle={`Which times of day would be best to have your one-time onboarding call with your trainer?`}
        onContinue={onClick}
      >
        <Tags tags={obWindowsCopy} onTagClick={selectTag} selected={tags} />
      </TagsPageLayout>
    </>
  );
}
